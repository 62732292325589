.login-container{
    width: 100% !important;
    height: 100%;
    background: #FFF;
    padding: 0px !important;
    max-width: 100% !important;
    display: flex !important;

    .left-box{
        flex: .50;
        padding: 0px !important;
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        justify-content: center !important;
        background-color: #FFF;

        .logo-img{
            position: absolute;
            left: 0;
            top: 0;
            mix-blend-mode: multiply;
        }

        .login-box{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 60%;

            .login-box-title{
                color: #000;
                font-family: "Montserrat",sans-serif;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 32px;
                letter-spacing: -0.12px;
                text-align: start;
                width: 100%;
                z-index: 999;
            }

            .email-text{
                color: #334155;
                font-family: "Montserrat",sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
            }

            .login-input{
                border-radius: 8px;
                border: 1px solid #CBD5E1;
                background: #FFF;
            }

            .error-text{
                color: rgb(179, 23, 23);
                font-size: 13px;
                margin-top: 2px;
            }

            .btn-box{
                width: 100%;
                text-align: end;
                padding:8px 0px;

                .forgot-pwd-btn{
                    color: #3A84C9;
                    font-family: "Montserrat",sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 22px; /* 157.143% */
                    text-decoration-line: underline;
                    text-transform: none;
                    background: none;
                    border: none;
                    padding: 0;
                    cursor: pointer;
                }
            }

            .login-btn-box{
                padding-bottom: 0;

                &::after{
                    content: "";
                    height: 1px;
                    width: 90%;
                    background: rgba(100, 116, 139, 0.20);
                    margin-top: 24px;
                }

                .login-btn{
                    border-radius: 8px;
                    background: linear-gradient(90deg, #CAB354 0%, #A68B3C 100%);
                    box-shadow: 0px 6px 15px -3px rgba(0, 0, 0, 0.15);
                    text-transform: none;
                    color: #FFF;
                    font-family: "Montserrat",sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 24px; /* 150% */
                }
            }
            .social-login-btn{
                border-radius: 8px;
                border: 1px solid rgba(140, 140, 140, 0.20);
                background: #FFF;
                color: #000;
                font-family: "Montserrat",sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 22px; /* 137.5% */
                text-transform: none;
                margin-top: 24px;

                .google-img{
                    width: 24px;
                    height: 24px;
                    margin-right: 7px;
                }
            }

            .terms-box{
                display: flex;
                align-items: center;
                margin-top: 20px;

                .terms-check{
                    padding-left: 0px;
                }

                .terms-text{
                    color: #0F172A;
                    font-family: "Inter",sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 22px;
                }
            }

            .account-text{
                color: #0F172A;
                font-family: "Inter",sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;

                .signup-btn{
                    color: #3A84C9;
                    font-weight: 700;
                    text-decoration-line: underline;
                    text-transform: none;
                    background: none;
                    border: none;
                    padding: 0;
                    cursor: pointer;
                    margin-top: 26px;
                }
            }
        }
    }

    .right-box{
        flex: .50;
        background: linear-gradient(180deg, #D0B33E 0%, #996D26 100%);
        position: relative;

        .popup-image{
            height: 100%;
            position: absolute;
            left: -15px;
            right: 0px;
            width: -moz-available;
        }
    }
}