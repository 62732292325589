.welcome-page-container {
    width: 100% !important;
    height: 100%;
    background: #FFF;
    padding: 0px !important;
    max-width: 100% !important;
    display: flex !important;

    .left-box {
        flex: .58;
        padding: 0px !important;
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        justify-content: center !important;
        background-color: #3A84C90D;

        .logo-img {
            position: absolute;
            left: 0;
            top: 0;
            mix-blend-mode: multiply;
        }

        .welcome-page-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 60%;

            .welcome-page-title {
                color: #000;
                font-family: Montserrat, sans-serif;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 32px;
                letter-spacing: -0.12px;
                text-align: center;
                width: 100%;
                z-index: 999;
            }

            .login-btn {
                border-radius: 8px;
                background: linear-gradient(90deg, #CBB454 0%, #A3873A 100%);
                box-shadow: 0px 6px 15px -3px rgba(0, 0, 0, 0.15);
                width: 395px;
                height: 45px;
                z-index: 999;
                color: #fff;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 22px;
                margin-top: 40px;
                text-transform: capitalize;
                font-family: Montserrat, sans-serif;
            }

            .sign-up-btn {
                border-radius: 8px;
                border: 1px solid rgba(140, 140, 140, 0.20);
                background: #FFF;
                width: 395px;
                height: 45px;
                z-index: 999;
                color: #000;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 22px;
                margin-top: 24px;
                text-transform: capitalize;
                font-family: Montserrat, sans-serif;
            }


        }
    }

    .right-box {
        flex: .42;
        background: linear-gradient(180deg, #D0B33E 0%, #996D26 100%);
        position: relative;

        .popup-image {
            height: 100%;
            position: absolute;
            left: -34px;
            right: 0px;
            width: calc(100% + 34px);
        }
    }
}